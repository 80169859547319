import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import theme from './styles/theme';
import './App.css';
import Home from './components/Home';
import Header from './components/Header';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
          <Header />
          <Home />
          <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;
