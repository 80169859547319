import { createTheme } from '@mui/material/styles';

export const Colors = {
	PRIMARY: '#ffffff',
	SECONDARY: '#0f0f0f',
	Background: '#0f0f0f',
    SecondaryBackground: 'rgba(255, 255, 255, 0.1)',
	SecondaryBackgroundHover: 'rgba(255, 255, 255, 0.15)',
	black: '#000000',
    Border: '1px solid rgba(255, 255, 255, 0.16)',
    Description: 'rgba(255, 255, 255, 0.85)',
    SubTitle: 'rgba(255, 255, 255, 0.35)',
};

export const Fonts = {
	ShareTech: 'Share Tech',
	ShareTechMono: 'Share Tech Mono',
	Bebas: 'Bebas Neue',
	Cedarville: 'Cedarville Cursive',
	Roboto: 'Roboto Condensed',
	Shadows: 'Shadows Into Light',
	Space: 'Space Grotesk',
	Judge: 'Judge',
};

export const Transition = {
	Default: 'all 0.5s ease 0s',
};

const DefaultTheme = createTheme({
	palette: {
        mode: 'dark',
		primary: {
			main: Colors.PRIMARY,
		},
		secondary: {
			main: Colors.SECONDARY,
		},
		// background: {
		// 	default: Colors.PRIMARY,
		// },
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 500,
			md: 900,
			lg: 1125,
			xl: 1536,
		},
	},
	typography: {
		fontSize: 16,
		h1: {
			fontWeight: 400,
			fontSize: '5rem',
		},
		h2: {
			fontWeight: 400,
			fontSize: '3.5rem',
			fontFamily: Fonts.Shadows,
		},
		h3: {
			fontWeight: 700,
			fontSize: '2rem',
		},
		h4: {
			fontWeight: 400,
			fontSize: '1.75rem',
		},
		h5: {
			fontWeight: 500,
			fontSize: '1.5rem',
			fontFamily: Fonts.Space,
		},
		h6: {
			fontWeight: 500,
			fontSize: '1.25rem',
            fontFamily: Fonts.Space,
		},
		body1: {
			fontSize: '1.125rem',
		},
		body2: {
			fontSize: '1rem',
		},
		fontFamily: 'Roboto',
	},
});

export default DefaultTheme;