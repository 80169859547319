import { useState } from "react";
import { Box, Button, Collapse, Container, Link, List, ListItem, Modal, Paper, Typography } from "@mui/material";
import AliceCarousel from 'react-alice-carousel';

import syndicetImg from "../assets/images/syndicet-2.png";
import bylawsImg from "../assets/images/CETS-bylaws.png";
import bylawsImgSmall from "../assets/images/CETS-bylaws_sm.png";
import closeImg from "../assets/images/close.png";
import whoAreYou from "../assets/images/Who_Are_You_2.mp4";
import spray from "../assets/images/spraypain_short.mp4";
import { modalStyle } from "../styles/modal";

import 'react-alice-carousel/lib/alice-carousel.css';

const handleDragStart = (e) => e.preventDefault();

export default function Home() {
    const [modalOpen, setModalOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    
    const handleModalClose = () => {
        setModalOpen(false);
    }

    const carouselItems = [
        <img src={syndicetImg} width="100%" />,
        <video src={whoAreYou} controls width="100%" loop />,
        <video src={spray} controls width="100%" loop />,
    ]

    return (
        <Container>
            <Box sx={{ marginX: { xs: 0, md: 8 } }}>
                <Paper elevation={3} sx={{ padding: 3 }}>
                    <Typography variant="h2" mb={5}>The Syndicet</Typography>
                    
                    <Box mb={2}>
                        <Link href="https://docs.google.com/document/d/1hvxDZN6hqGBG99KwdsHKeQPJBdT70TAZJO5NsBizRxM/edit?usp=sharing" target="_blank"><Typography variant="h6">Cets Bylaws</Typography></Link>
                    </Box>
                    
                    <Box mb={2}>
                        <Link href="https://docs.google.com/document/d/1l5CiN4Br-jeBus85KmtSHBNU223rGAEoFEEYroFEIQg/edit?usp=sharing" target="_blank"><Typography variant="h6">Voting Procedures</Typography></Link>
                    </Box>
                    <Box mb={2}>
                        <Link href="https://docs.google.com/document/d/1wr-Qu8xSrWep1dusJROqFa1LLuLbAu632U9SEHjLEHE/edit?usp=sharing" target="_blank"><Typography variant="h6">Code of Conduct</Typography></Link>
                    </Box>
 
{/* 
                    <Box mb={2}> 
                        <Link href="https://docs.google.com/document/d/1T06e9dZA2mt0eE9gbM0Z23WxGmbs1Xp109IqtGG0Xjk/edit?usp=sharing" target="_blank">Thesis</Link>
                    </Box> */}
                </Paper>

                <Paper elevation={3} sx={{ padding: 3, marginTop: 8 }}>
                    <Typography variant="h2" mb={5}>Syndicet 4 Dummies</Typography>
                    {/* <Typography variant="body1" mt={2}>governance thesis</Typography> */}
                    
                    <Paper elevation={0} sx={{ marginTop: 3, width: { xs: '100%', md: '100%' } }}>
                        <Box display={{ xs: 'none', md: 'block' }}>
                            <Button onClick={() => setModalOpen(true)}>
                                <img src={bylawsImgSmall} width="100%" />
                            </Button>
                        </Box>

                        <Box display={{ xs: 'block', md: 'none' }}>
                            <Link href="/CETS-bylaws.png" target="_blank">
                                <img src={bylawsImgSmall} width="100%" />
                            </Link>
                        </Box>
                    </Paper>
                </Paper>


                <Paper elevation={3} sx={{ padding: 3, marginTop: 8 }}>
                    <Typography variant="h2"> Governance Thesis</Typography>
                    <Box mt={5}>
                    <Typography variant="h5">DAOs don’t work.</Typography>
                    <Typography>That’s why we created something better.</Typography>

                    <Typography>Introducing a new paradigm of web3 governance: the <strong>Syndicate Framework</strong>.</Typography>

                    <Typography mt={5} variant="h6">WHY DAOS DON’T WORK</Typography>

                    <Typography mb={2}>We did the research. We spoke with thought leaders all across the ecosystem. We pulled it apart and tried to put it back together dozens of different ways. What we learned amounted to this simple fact: DAOs - as they exist today - do not work for the majority of NFT projects. </Typography>

                    <Typography mb={2}>In its purest form, a decentralized autonomous organization (DAO) has no central governing body and relies on its members to make proposals, vote on the proposals presented, and most importantly - remain active and engaged in the process. There is no doubt that this concept works wonders in very specific scenarios. But that isn’t the case for Cets.</Typography>
                    {!isChecked && <Button variant="outlined" onClick={() => setIsChecked(true)}>Read More</Button>}
                    <Collapse in={isChecked}>

                    <Typography mb={2}>But why won't a DAO structure work for Cets? It’s simple: the DAO structure is not agile or informed enough to keep up with the fast-paced and demanding nature of the NFT ecosystem, even in a bear market. To rely on a large semi-engaged community of thousands of people all over the world to make every decision for the organization is staggeringly cumbersome and slow.</Typography>

                    <Typography mb={2}>Consider this, too: anyone can join an NFT project - whether they align with the goals of the community or not. Imagine unqualified people making egregious proposals, whales or bad actors influencing votes, or worse.</Typography>

                    <Typography mb={2}>There is no outcome for cetsDAO that doesn't result in failure.</Typography>

<Typography variant="h6" mt={5}>ENTER: THE SYNDICET</Typography>

<Typography mb={2}>Syndicate: "An association of people or firms formed to promote a common interest or carry out a business enterprise."</Typography>

<Typography mb={2}>cetsDAO is no more. Goodbye traditional DAO model; hello dynamic governance structure that favors agility and strong leadership. Cets has developed a new hybrid model that blends the best parts of what people expect from a DAO with a traditional corporate executive structure.</Typography>

<Typography mb={2}>Cets will thrive under a governance framework that empowers executive leadership and decision-making, supported by a community that chooses their leaders and holds them accountable. The Executive Team will be incentivized to act in the interest of the community and make key decisions on behalf of the organization. </Typography>

<Typography mb={2}>This is The Syndicate Framework, brought to you by Cets.</Typography>

<Typography mb={2}>This is The Syndicet.</Typography>

<Typography variant="h6" mt={5}>THE POWER OF THE SYNDICET</Typography>

<Typography mb={2}>The Syndicet Framework is a hybrid governance model. A finite community of members are responsible for supporting the project - and each other. A small, qualified Executive Team is responsible for day-to-day decisions, management, and execution. The community chooses its leaders. The community supports the decisions made by those leaders. If the community disapproves, the community can remove leaders with cause, or choose new leaders in the next cycle.</Typography>

<Typography mb={2}>An Advisory Board will be appointed to oversee Executive actions and provide the community with a means to curtail nefarious actions by a rogue Executive Team. Committees will be formed to perform an array of tasks according to the will of the Syndicet and the needs of the organization.</Typography>

<Typography variant="h6" mt={5}>GIVING CETS A VOICE</Typography>

<Typography><strong>Voting</strong></Typography>
<Typography>Members of The Syndicet will be able to vote on three categories of proposals and ballot measures, in addition to creating proposals themselves.</Typography>

<Typography mt={2}>All eligible Cets can vote on:</Typography>
<List>
    <ListItem>1. Election of the Executive Team.</ListItem>
    <ListItem>2. Impeachment of an Executive Team member.</ListItem>
    <ListItem>3. Significant decisions that alter the course of Cets (like burning Enlightenment art, going back to “Creck”, or changes to Bylaws and other procedural documentation).</ListItem>
</List>

<Typography mt={2}><strong>Proposals</strong></Typography>
<Typography>Syndicet Members will also be able to propose their actionable ideas to the Executive Team through a 3-Step Proposal Process:</Typography>
<List>
    <ListItem>1. Community Proposal and Discussion.</ListItem>
    <ListItem>2. Governance Review.</ListItem>
    <ListItem>3. Executive Approval/Denial.</ListItem>
</List>


<Typography my={2}>Not all proposals will be approved. Still, this process gives the community an opportunity for community members' voices to be heard and to iterate on proposals presented by their peers. The most actionable, reasonable, sensible, and executable proposals have the greatest likelihood of being approved and put into motion.</Typography>

<Typography mb={2}>Ultimately, the Executive Team has the right to accept or deny proposals as they see fit. Since the elected Executive Team has the deepest understanding of Cets' short and long-term goals, the financial status of the organization, and any current obligations that might not have been accounted for in the proposal, they will be responsible for managing these variables while ensuring the best ideas are executed effectively.</Typography>

<Typography variant="h6" mt={5}>FUTUREPROOFING WEB3 GOVERNANCE</Typography>

<Typography mb={2}>Sovereignty and transparency are two tenets of web3 culture that Cets are eager to endorse. Cets' proprietary on-chain voting platform will ensure that all decisions made by the community are recorded on a public ledger forever. All crypto-based assets will be held in multi-signature wallets to maximize security and ensure that financial transactions are only made with intention and forethought. </Typography>

<Typography mb={2}>The Syndicet Framework also seeks to establish a pathway through which engaged community members can access opportunities to gain experience, skills, and support from other talented members. Plans are already underway to promote the creative subculture of Cets by leveraging Cets' most talented and engaged creators. </Typography>

<Typography mb={2}>There is no limit to what a community like Cets can accomplish with strong leadership, a process that hones the greatest ideas into executable tasks, and a bit of that FK U attitude.</Typography>

<Typography mb={5}>This is how Cets will survive. Tomorrow’s kingpins are today’s Cets fosho’. </Typography>

<Typography mb={2}><em>Welcome to The Syndicet.</em></Typography>
</Collapse>
</Box>
                </Paper>

                {/* <Box mt={8} display="flex" justifyContent="center">
                    <Box width={{ xs: '90%', md: '50%' }} >
                        <AliceCarousel mouseTracking items={carouselItems} autoHeight autoPlay infinite autoPlayInterval={6000} />
                    </Box>
                </Box> */}

            </Box>

            <Modal
            open={modalOpen}
            onClose={handleModalClose}
            >
            <Box>
                <Box className="close-img" onClick={handleModalClose}>
                        <img src={closeImg} />
                </Box>

                <Box sx={modalStyle} padding={3}>
                    <img src={bylawsImg} width="100%" />
                </Box>
            </Box>
                
            </Modal>
        </Container>
    );
}
