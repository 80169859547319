import { Paper, Container, Box, Typography, Link } from "@mui/material";

export default function GuestFooter() {
    return (
      <Paper sx={{marginTop: 'calc(5% + 60px)',
      width: '100%',
    //   position: 'fixed',
      bottom: 0,
      width: '100%'
      }} component="footer" square variant="outlined">
        <Container maxWidth="lg">
          {/* <Box
            sx={{
              flexGrow: 1,
              justifyContent: "center",
              display: "flex",
              my:1
            }}
          >
          </Box> */}
  
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "center",
              display: "flex",
              mb: 2,
              mt: 2,
            }}
          >
                <Box mb={2} mr={3}>
                    <Link href="https://linktr.ee/cetsfk" target="_blank">
                        <Typography variant="h6">linktr.ee/cetsfk</Typography>
                    </Link>
                </Box>
                <Box mb={2} mr={3}>
                    <Link href="https://x.com/cetsfk" target="_blank">
                        <Typography variant="h6">x.com/cetsfk</Typography>
                    </Link>
                </Box>
                <Box mb={2}>
                    <Link href="https://cets.wtf" target="_blank">
                        <Typography variant="h6">cets.wtf</Typography>
                    </Link>
                </Box>
          </Box>
        </Container>
      </Paper>
    );
  }