import { Avatar, Box, Container } from "@mui/material";
import doodleBanner from "../assets/images/Doodle_Banner.png";
import logo from "../assets/images/cets-logo3.png";

export default function Header() {
    return (
        <Box disableGutters>
            <Box>
            <Box sx={{
                position: "relative",
                top: "-20%",
            }}>
                <img width="100%" src={doodleBanner} />
            </Box>

            <Box sx={{ 
                    width: { xs: 90, md: 200 }, 
                    height: { xs: 90, md: 200 },
                    top: { xs: -50, md: -100 },
                    marginLeft: { xs: 5, md: 10 }, 
                    position: "relative",
                    
                }}>
        
                <Box display="flex" justifyContent="center" alignItems="center" sx={{
                    bgcolor: 'black',
                    borderRadius: '50%',
                    width: '100%',
                    height: '100%',
                }}>
                    <img src={logo} width="65%" />
                </Box>

            </Box>
            </Box>


        </Box>
    );
}