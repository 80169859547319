export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '80%', md: '80%' },
    // bgcolor: 'rgba(255, 255, 255, 0.4)',
    bgcolor: '#030303',
    // border: '2px solid #5f5f5f',
    // border: '2px solid rgba(0, 0, 0, 0.26)',
    boxShadow: 24,
    // borderRadius: 2,
    p: 1,
    outline: 'none',
    overflowY: 'scroll',
  };